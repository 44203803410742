import React from 'react'

function SyaratKetentuan() {
  return (
    <div id='syarat'>
    <div className='s-heading'>
    <h1>Syarat & Ketentuan</h1>
    <div className='syarat-text'>
       <p>  <ol>  
<li>Pembelian 1 (satu) produk elektronik POLYTRON (tidak berlaku untuk produk EV electric vehicle) dengan ketentuan minimum harga pembelian per unit produk sebesar Rp1.500.000 (satu juta lima ratus ribu rupiah) yang dibeli oleh pelanggan/customers. Pembeli/pelanggan akan mendapatkan 1 (satu) kupon per produk yang memiliki harga minimum Rp1.500.000 (satu juta lima ratus ribu rupiah).</li><br />
<li>Periode pembelian produk: 26 Agustus 2024 - 19 September 2024. Jika pembelian dilakukan oleh peserta diluar tanggal yang sudah dicantumkan di S&K, maka entri peserta tidak akan dianggap valid.</li><br />
<li>Untuk bergabung dengan program ini, calon peserta wajib untuk mengisi Google Form pada alamat email Google Form: ufclive@mola.tv serta mengirimkan dokumen peserta yang dibutuhkan dalam program ini sebagaimana dimaksud dalam poin 4 di bawah ini. Jika peserta mengirimkan dokumennya pada kanal Mola lainnya, seperti direct message Instagram, maka entri peserta tidak akan dianggap valid dan secara otomatis akan didiskualifikasi dari program undian.</li><br />
<li>Untuk mengikuti program ini, peserta wajib menyerahkan semua dokumen berikut:</li>
    <ol>
    <li>Foto invoice atau struk bukti pembelian offline atau online.</li>
    <li>Foto KTP.</li>
    <li>Foto paspor. Paspor harus berlaku minimum 12 (dua belas) bulan terhitung mundur dari tanggal penarikan undian yaitu 28 September 2024.</li>
    <li>Foto Kartu Garansi produk Polytron yang dibeli.</li>
    </ol>
    <br />
<li>Jika peserta tidak memberikan seluruh dokumen yang diminta di poin 4 di atas, maka peserta tersebut tidak akan dimasukkan dalam undian penarikan pemenang.</li><br />
<li>Pastikan foto dokumen yang dicantumkan terlihat jelas (tidak blur) sehingga entri dapat diproses. Jika foto dokumen yang diupload tidak dapat terlihat dan terbaca dengan jelas, maka entri peserta tidak akan dianggap valid.</li><br />
<li>Periode pendaftaran program dan pengisian Google Form dari ufclive@mola.tv adalah 26 Agustus 2024 - 26 September 2024. Jika pendaftaran dilakukan oleh peserta diluar tanggal yang sudah dicantumkan di S&K, maka entri peserta tidak akan dianggap valid.</li><br />
<li>Pihak Mola dan Polytron akan memvalidasi entri peserta, jika entri peserta valid (contoh, dokumen yang disertakan tidak lengkap, struk pembelian dan kartu garansi produk tidak valid) maka peserta berhak untuk ikut dalam penarikan undian.</li><br />
<li>Pemenang program akan dihubungi langsung oleh pihak Mola.</li><br />
<li>Pengumuman pemenang akan diumumkan pada akun instagram @mola.sport dan @polytron.auvi tanggal 28 September 2024.</li><br />
<li>Hadiah program tidak dapat dikonversikan dalam bentuk uang.</li><br />
<li>Jadwal keberangkatan dan lokasi tujuan hadiah program dapat berubah sewaktu-waktu berdasarkan keputusan penyelenggara undian. Pemenang tidak dapat membatalkan keberangkatan atau mengubah jadwal yang telah ditentukan.</li><br />
<li>Pemenang wajib memberikan konfirmasi untuk dapat mengikuti hadiah perjalanan selambat-lambatnya 7x24 jam setelah dihubungi oleh tim Mola. Jika pemenang tidak memberikan konfirmasinya dalam tenggat waktu yang ditentukan maka pemenang dinyatakan gugur.</li><br />
<li>Pemenang tidak akan dipungut biaya pajak.</li><br />
<li>Biaya pembuatan visa dan akomodasi yang berkaitan dengan program perjalanan Mix Martial Arts (MMA) Live Trip ditanggung oleh pihak penyelenggara yaitu PT Global Media Visual / Mola.</li><br />
<li>Polytron dan Mola berhak untuk menganulir klaim kupon jika terindikasi adanya kecurangan</li><br />
<li>Pemenang program MMA Live Trip periode pertama tidak dapat berpartisipasi pada program ini.</li><br />
</ol>
</p> 
</div> 
    </div>
</div>
  )
}

export default SyaratKetentuan